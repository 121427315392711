import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { AppState } from '../../reducers/rootReducer';
import { templatesListLoaded } from '../../reducers/templates';
import TemplateCard from '../../components/TemplateCard/TemplateCard';
import { downloadTextFile } from '../../utils/downloadFile';
import styles from './HomePage.module.scss';
import CreateNewTemplateBlock from './components/createNewTemplateBlock/CreateNewTemplateBlock';

export enum Params {
    ID = 'id',
    TITLE = 'title'
}

const today = new Date().toLocaleDateString();
const time = new Date().toLocaleTimeString('RU-ru', { timeStyle: 'short' });

const HomePage: FunctionComponent = () => {
    const dispatch = useDispatch();
    const { templatesList } = useSelector((state: AppState) => state.templates);

    const clearStore = () => {
        dispatch(templatesListLoaded([]));
    };

    const downloadStore = () => {
        downloadTextFile({ fileData: templatesList, fileName: `templatesStore-${today}-${time}.json` });
    };

    return (
        <div className={styles.homePage}>
            {templatesList.length !== 0 && (
                <>
                    <div className={styles.actionButtons}>
                        <div className={classNames(styles.actionButton, styles.clear)} onClick={clearStore}>
                            Очистить хранилище
                        </div>
                        <div className={styles.actionButton} onClick={downloadStore}>
                            Выгрузить данные хранилища
                        </div>
                        <div className={classNames(styles.actionButton, styles.disabled)}>
                            Загрузить данные в хранилище
                        </div>
                    </div>
                    <div className={styles.title}>Шаблоны</div>
                    <div className={styles.templatesList}>
                        {templatesList.map((template) => (
                            <TemplateCard key={template.id} emailTemplate={template} />
                        ))}
                    </div>
                </>
            )}
            <CreateNewTemplateBlock />
        </div>
    );
};

export default HomePage;
