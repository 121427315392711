import React, { FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../HomePage.module.scss';
import { templatesListLoaded } from '../../../../reducers/templates';
import getNewTemplateId from '../../utils/getNewTemplateId';
import { AppState } from '../../../../reducers/rootReducer';
import { EmailTemplate } from '../../../../constants/types';
import defaultDesign from './constants/defaultDesign';

const CreateNewTemplateBlock: FunctionComponent = () => {
    const dispatch = useDispatch();
    const { templatesList } = useSelector((state: AppState) => state.templates);
    const [newTemplateTitle, setNewTemplateTitle] = useState<string>('');
    const isTitle = Boolean(newTemplateTitle);

    const changeInput = (event: any) => {
        const {
            target: { value }
        } = event;

        setNewTemplateTitle(value);
    };

    const createNewTemplate = () => {
        const newTemplateId = getNewTemplateId();
        const newTemplate: EmailTemplate = { design: defaultDesign, title: newTemplateTitle, id: newTemplateId };
        const { title, id } = newTemplate;
        const templatesIds = templatesList.map((template) => template.id);
        if (!title || !id || templatesIds.includes(id)) {
            return;
        }
        dispatch(templatesListLoaded([...templatesList, newTemplate]));
        setNewTemplateTitle('');
    };

    return (
        <>
            <div className={styles.form}>
                <input
                    onChange={changeInput}
                    placeholder="Название шаблона"
                    className={styles.input}
                    value={newTemplateTitle}
                    type="text"
                />
            </div>
            <div className={classNames(styles.newButton, { [styles.disabled]: !isTitle })} onClick={createNewTemplate}>
                Создать новый шаблон
            </div>
        </>
    );
};

export default CreateNewTemplateBlock;
