import React, { FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { EmailTemplate } from '../../../../constants/types';
import { templatesListLoaded, updateTemplate, updateTemplateList } from '../../../../reducers/templates';
import { Params } from '../../../../pages/HomePage/HomePage';
import getNewTemplateId from '../../../../pages/HomePage/utils/getNewTemplateId';
import closeIconPath from '../../../../img/closeIcon.svg';
import styles from './TemplateCardModal.module.scss';

// @ts-ignore
import ConfirmationModal from './components/confirmationModal/ConfirmationModal';
import ActionType from './constants/ActionType';

interface Props {
    emailTemplate: EmailTemplate;
    templatesList: EmailTemplate[];
    onClose(): void;
}

const TemplateCardModal: FunctionComponent<Props> = ({ emailTemplate, templatesList, onClose }) => {
    const dispatch = useDispatch();
    const [notificationType, setNotificationType] = useState<ActionType | undefined>();
    const [error, setErrorMessage] = useState<string | undefined>();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [updatedTemplate, setTemplate] = useState<EmailTemplate>(emailTemplate);
    const templatesIds = templatesList.map((template) => template.id);

    const handleChange = (type: Params) => (event: any) => {
        setErrorMessage(undefined);
        const {
            target: { value }
        } = event;
        const newTemplateState = { ...updatedTemplate, [type]: type === Params.ID ? value.replaceAll(' ', '') : value };
        const { id, title } = newTemplateState;

        setTemplate(newTemplateState);
        switch (type) {
            case Params.ID:
                if (!id || id === emailTemplate.id || templatesIds.includes(id)) {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
                return;
            case Params.TITLE:
                if (!id || !title || title === emailTemplate.title) {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
        }
    };

    const addNotificationModal = (type: ActionType | undefined) => () => {
        setNotificationType(type);
    };

    const handleEdit = () => {
        const isOldId = updatedTemplate.id === emailTemplate.id;
        if (isOldId) {
            dispatch(updateTemplate(updatedTemplate));
        }

        dispatch(updateTemplateList({ oldId: emailTemplate.id, template: updatedTemplate }));
    };

    const handleDelete = () => {
        const filteredList = templatesList.filter((template) => template.id !== emailTemplate.id);
        dispatch(templatesListLoaded(filteredList ? filteredList : []));
    };

    const handleCopy = () => {
        setNotificationType(undefined);
        const duplicatedTemplateId = getNewTemplateId();
        const isNotUniqueId = Boolean(templatesList.find(({ id: templateId }) => templateId === duplicatedTemplateId));
        if (isNotUniqueId) {
            setErrorMessage('Идентификатор не уникален, попробуйте ещё раз');
            return;
        }
        const isTitleSimilar = updatedTemplate.title === emailTemplate.title;
        if (isTitleSimilar) {
            setErrorMessage('Введите другой заголовок');
            return;
        }
        dispatch(templatesListLoaded([...templatesList, { ...updatedTemplate, id: duplicatedTemplateId }]));
        onClose();
    };

    const handleSubmit = () => {
        switch (notificationType) {
            case ActionType.DELETE:
                handleDelete();
                onClose();
                return;
            case ActionType.EDIT:
                handleEdit();
                onClose();
                return;
            case ActionType.DUPLICATE:
                handleCopy();
        }
    };

    return (
        <>
            <div className={styles.background}>
                {notificationType ? (
                    <ConfirmationModal
                        notificationType={notificationType}
                        onClose={addNotificationModal(undefined)}
                        onSubmit={handleSubmit}
                    />
                ) : (
                    <div className={styles.modal}>
                        <img src={closeIconPath} alt={''} className={styles.close} onClick={onClose} />
                        <div className={styles.title}>Редактирование {emailTemplate.id}</div>
                        <div className={styles.inputs}>
                            <div className={styles.inputWrapper}>
                                <input
                                    className={styles.input}
                                    onChange={handleChange(Params.TITLE)}
                                    value={updatedTemplate.title}
                                />
                                {error ? <div className={styles.inputError}>{error}</div> : null}
                            </div>
                        </div>

                        <div className={styles.buttonsWrapper}>
                            <div
                                onClick={addNotificationModal(ActionType.EDIT)}
                                className={classNames(styles.button, styles.green, { [styles.disabled]: disabled })}
                            >
                                Сохранить
                            </div>
                            <div
                                onClick={addNotificationModal(ActionType.DELETE)}
                                className={classNames(styles.button, styles.red)}
                            >
                                Удалить шаблон
                            </div>
                            <div
                                onClick={addNotificationModal(ActionType.DUPLICATE)}
                                className={classNames(styles.button, styles.copy)}
                            >
                                Дублировать шаблон
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default TemplateCardModal;
