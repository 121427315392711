import React, {createContext, FunctionComponent, PropsWithChildren, useEffect, useState} from 'react';
import { debounce } from 'lodash-es';
import Media from "../constants/enums/Media";


const mediaQueriesMap: Record<Media, MediaQueryList> = {
    [Media.MOBILE]: window.matchMedia(`(max-width: ${Media.MOBILE}px)`),
    [Media.TABLET]: window.matchMedia(`(min-width: ${Media.TABLET}px)`),
    [Media.DESKTOP]: window.matchMedia(`(min-width: ${Media.DESKTOP}px)`)
};

const getMedia = (): Media[] =>
    Object.keys(mediaQueriesMap)
        .filter((media) => mediaQueriesMap[media as unknown as Media].matches)
        .map((item) => Number(item));

export interface MediaContextState {
    media: Media[];
}

const MediaContext = createContext<MediaContextState>({ media: getMedia() });

interface Props {

}
export const MediaContextProvider: FunctionComponent<PropsWithChildren<Props>> = ({ children }) => {
    const [contextState, setContextState] = useState<MediaContextState>(() => ({ media: getMedia() }));

    useEffect(() => {
        const handler = debounce(() => setContextState({ media: getMedia() }), 250);

        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    }, []);

    return <MediaContext.Provider value={contextState}>{children}</MediaContext.Provider>;
};

export default MediaContext;
