import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { EmailTemplate } from '../constants/types';
import actionCreator from './actionCreator';

export interface TemplatesState {
    templatesList: EmailTemplate[];
}

const initialState: TemplatesState = {
    templatesList: []
};

export const templatesListLoaded = actionCreator<EmailTemplate[]>('templatesListLoaded');
export const updateTemplate = actionCreator<EmailTemplate>('updateTemplate');
export const updateTemplateList = actionCreator<{ oldId: string; template: EmailTemplate }>('updateTemplateList');

export default reducerWithInitialState(initialState)
    .case(templatesListLoaded, (state, templatesList) => {
        // eslint-disable-next-line no-console
        console.debug('localStorage loaded');
        localStorage.setItem('templates', JSON.stringify(templatesList));
        return { ...state, templatesList: templatesList };
    })
    .case(updateTemplate, (state, emailTemplate) => {
        const nextList = state.templatesList.map((template) => {
            if (template.id !== emailTemplate.id) {
                return template;
            }
            return emailTemplate;
        });
        // eslint-disable-next-line no-console
        console.debug('localStorage updated');
        localStorage.setItem('templates', JSON.stringify(nextList));
        return { ...state, templatesList: nextList };
    })
    .case(updateTemplateList, (state, { oldId, template }) => {
        const filtered = state.templatesList.filter((template) => template.id !== oldId);
        const nextList = [...filtered, template];
        // eslint-disable-next-line no-console
        console.debug('localStorage updated');
        localStorage.setItem('templates', JSON.stringify(nextList));
        return { ...state, templatesList: nextList };
    });
