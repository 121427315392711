import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import store from './reducers/store';
import { MediaContextProvider } from './layouts/MediaContext';

const App: FunctionComponent = () => (
    <Provider store={store}>
        <MediaContextProvider>
            <AppRouter />
        </MediaContextProvider>
    </Provider>
);

export default App;
