import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import styles from '../../TemplateCardModal.module.scss';
import ActionType from '../../constants/ActionType';

interface Props {
    notificationType: ActionType;
    onSubmit(): void;
    onClose(): void;
}

const actionTitleByType: Record<ActionType, string> = {
    [ActionType.DELETE]: 'удалить шаблон',
    [ActionType.EDIT]: 'сохранить шаблон',
    [ActionType.DUPLICATE]: 'создать копию шаблона'
};

const ConfirmationModal: FunctionComponent<Props> = ({ notificationType, onSubmit, onClose }) => (
    <div className={classNames(styles.modal, styles.notification)}>
        <div className={styles.notificationTitle}>Уверены, что хотите {actionTitleByType[notificationType]}</div>
        <div onClick={onSubmit} className={classNames(styles.notificationButton, styles.green)}>
            Да
        </div>
        <div onClick={onClose} className={classNames(styles.notificationButton, styles.red)}>
            Нет
        </div>
    </div>
);

export default ConfirmationModal;
