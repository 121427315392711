import React, { FunctionComponent, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import routes from './constants/routes';
import NotFoundPage from './pages/notFoundPage/NotFoundPage';
import { templatesListLoaded } from './reducers/templates';

const AppRouter: FunctionComponent = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const JSONFromLocalStorage = localStorage.getItem('templates');
        const data = JSONFromLocalStorage ? JSON.parse(JSONFromLocalStorage) : [];

        if (!data) {
            return;
        }
        dispatch(templatesListLoaded(data));
    }, [dispatch]);

    return (
        <BrowserRouter>
            <Routes>
                {routes.map(({ path, component: Component }) => {
                    return <Route key={path} path={path} element={<Component />} />;
                })}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;
